<template>
  <admin-template
    title="categorias de produtos"
    newtext="nova categoria"
    newlink="/admin/products/categories/new"
  >
    <el-table
      :data="tableData"
      style="width: 100%"
      empty-text="vazio"
      v-loading="loading"
    >
      <el-table-column
        label="Nome da categoria do produto"
        prop="name"
      />

      <el-table-column
        align="right"
        width="80"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="success"
            @click="handleEdit(scope.row)"
            plain
          >
            Edit
          </el-button>
        </template>

      </el-table-column>
    </el-table>
  </admin-template>
</template>

<script>
import api from '@/services/api'
import adminTemplate from '@/components/admintemplate.vue'

export default {
  name: 'coupon-list',
  components: {
    adminTemplate
  },
  data() {
    return {
      tableData: [],
      search: '',
      loading: false,
    }
  },
  methods: {
    handleEdit(item) {
      this.$router.push(`/admin/products/categories/${item.id}`)
    },
    getCategories() {
      this.loading = true
      api.get('/backoffice/product/category', {
        headers: {
          'x-access-token': localStorage.token,
          'Content-Type': 'application/json',
        },
        mode: 'no-cors'
      })
        .then(({ data }) => {
          this.tableData = data
          this.loading = false
        })
        .catch(err => console.log(err))
    }
  },
  mounted() {
    this.getCategories()
  },
}
</script>

<style scoped>
.el-table,
.el-table * {
  box-sizing: border-box;
}
</style>
